* {
    box-sizing: border-box;
    
    font-family: "Noto Sans SC", Arial, sans-serif;
}

html, body {
    margin: 0px;
    padding: 0px;
    color: #000000;
    font-size: 17px;
    line-height: 1.5em;
    font-weight: normal;
}

.bg-white { 
    background-color: #ffffff; 
}
.bg-light { 
    background-color: #d8e9e6; /* rgba(190,218,213,1) bedad5 */
}
.bg-medium { 
    background-color: #bedad5; /* rgba(163,188,183,1) a3bcb7*/
}
.bg-dark { 
    background-color: #78b5ab; 
}
.bg-grey { 
  background-color: #999999; 
}
.bg-red { 
  background-color: #f29999;
}

section {
    max-width: 768px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0px;
}
section > .container {
    padding: 15px 15px 15px 15px;
}
@media (min-width: 769px) {
    section {
        margin-bottom: 70px;
    } 
    section.intro {
        margin-bottom: 0px;
    }
    section > .container {
        padding: 30px 30px 30px 30px;
    }
}

.section_language
{
  margin-bottom: 0px;
  color: #ffffff;
  text-align: right;
  /* position: fixed;
  top: 0px;
  right: 0px; */

}
.section_language button {
    background-color: #ffffff;
    border: 0px;
    margin: 0px 5px 4px 0px;
    padding: 1px 10px 2px 10px;
    cursor: pointer;
}
.section_language button:hover {
    background-color: #d8e9e6;
}
@media (min-width: 769px) {
    .section_language {
        position: relative;
    } 
    .section_language button {
        margin: 0px 0px 4px 4px;
    }
}

.logo_container {
    text-align: center;
    position: relative;
    height: 55vh;
}
.logo {
    max-width: 120px;
    position: absolute;
    bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
    /* xmargin-bottom: 20px; */
}
@media (min-width: 769px) {
    .logo_container {
        height: 65vh;
    }
    .logo {
        max-width: 200px;
        position: absolute;
        bottom: 0px;
        margin-left: auto;
        margin-right: auto;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        left: 50%;
    }
}

.bg {
    position: fixed;
    max-width: 100%;
    z-index: -1;
    background: #000000;
    background-image: url("/img/bg_mobil.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 54% bottom;
    /* background-position: center bottom; */
    width: 100vw;
    height: 80vh;
}
@media (min-width: 790px) {
    .bg {
        background-image: url("/img/bg.jpg");
        background-position: center bottom; 
        height: 100vh;
    }
}

/* Haupt Titel */
h1 { 
  color: #000000;
  font-size: 2.0em;
  line-height: 1.2em;
  font-weight: 900;
  margin: 0 0 15px 0;
}
@media (min-width: 790px) {
    h1 { 
      font-size: 3em;
      line-height: 1.2em;
      margin: 0 0 30px 0;
    } 
}
/* Section Titel */
h2 {
    color: #000000;
    font-size: 1.7em;
    line-height: 1.3em;
    font-weight: 900;
    margin: 0 0 15px 0;
}
/* Artikel Titel */
h3 {
    color: #000000;
    font-size: 1.4em;
    line-height: 1.3em;
    font-weight: 900;
    margin: 0px 0 15px 0;
}
/* Artikel Catchword */
h4 {
    color: #000000;
    font-size: 1em;
    line-height: 1.3em;
    font-weight: 900;
    margin: 0px 0 0px 0;
}

p {
    color: #000000;
    font-size: 1em;
    line-height: 1.5em;
    font-weight: normal;
}
p.lead {
    font-weight: 700;
}

p.legende {
    font-size: 0.8em;
    line-height: 1.5em;
}


a, a:link, a:active, a:visited, a:focus, a:hover {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}

.link_bold, .link_bold:link, .link_bold:active, .link_bold:visited, .link_bold:focus, .link_bold:hover {
  color: #000000;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

a:hover, .link_bold:hover {
    color: #78b5ab;
}
footer a:hover {
    color: #bedad5;
}

article .link_bold:hover
{
  color: #bedad5;
}

.results_container a, .results_container a:link, .results_container a:active, .results_container a:visited, .results_container a:focus {
    color: #486c66;
    text-decoration: none;
    cursor: pointer;
}
.results_container a:hover {
    color: #78b5ab;
}

.articlelist_more
{
    text-align: center;
}

.articlelist_more a, .articlelist_more a:link, .articlelist_more a:active, .articlelist_more a:visited, .articlelist_more a:focus {
    color: #486c66;
    text-decoration: none;
    cursor: pointer;
}
.results_container a:hover {
    color: #78b5ab;
}


hr {
    display: block;
    border-style: inset;
    border-width: 0px;
    border-top: 1px solid #78b5ab;
    margin-top: 15px;
}
.bg-dark hr {
    border-top: 1px solid #bedad5;
}

/* PARTNER */
.partner_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    -webkit-align-items: baseline;
            align-items: baseline;
    margin-top: 15px;
}
.partner {
    padding-right: 8px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}
.partner:nth-child(4) {
    padding-right: 0px;
}
.partner img {
    max-width: 100%;
    max-height: 40px;
}
@media (min-width: 769px) {
      
}

/* SEARCH */
section.search {
    margin-bottom: 0px;
}
.search .container {
    
}
.search_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-align-content: flex-start;
            align-content: flex-start;
}
input[type=text] {
    background-color: #ffffff;
    color: #000000;
    border-width: 0px;
    border-radius: 0px;
    font-size: 1em;
    padding: 15px;
    width: 100%;
  }

input[type=submit] {
    background-color: #a3bcb7;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    cursor: pointer;
    font-size: 1em;
    padding: 15px 25px 15px 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

/* ARTICLE */
article section {
    margin: 20px 0 20px 0;
}
article a, article a:link, article a:active, article a:visited, article a:focus, article a:hover {
    color: #000000;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}
article a:hover {
    color: #bedad5;
}
article img {
    max-width: 100%;
}
.article_text {
    padding-top: 5px;
}
.article_image {
    position: relative;
    line-height: 0px;
    margin-bottom: 5px;
}
.article_logo {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    max-width: 100px;
}
@media (min-width: 700px) {
    .article_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        -webkit-align-content: flex-start;
                align-content: flex-start;
    }
    .article_text {
        /* flex-grow: 1; */
        width: 50%;
        padding-top: 0px;
    }
    .article_image {
        margin-right: 15px;
        /* flex-basis: 100%; */
        /* flex-shrink : 0;  */
        width: 50%;
    }
    .article_logo {
        bottom: 0;
        left: 0;
    }
}





/* RESULTS */
ul {
    margin: 3px;
    padding: 2px;
    -webkit-padding-start: 15px;
            padding-inline-start: 15px;
    -webkit-margin-before: 3px;
            margin-block-start: 3px;
    -webkit-margin-after: 3px;
            margin-block-end: 3px;
}
/* li {
    list-style-type: none;
    margin: 3px;
    padding: 0px 5px 0px 5px;
    background-color: #bedad5;
} */
li {
    margin: 0px;
    padding: 3px 5px 3px 5px;

    font-size: 0.8em;
    line-height: 1.5em;
}

section.results {
    margin-bottom: 0px;
}
.results .container {
    padding-top: 0px;
    padding-bottom: 0px;
}
.results_block {
    padding-bottom: 30px;
}
.results_address {
    font-size: 0.8em;
    line-height: 1.5em;
}
@media (min-width: 769px) {
    .results_address {
        font-size: 1em;
        line-height: 1.5em;
    }
}
.results h3 {
    margin: 0px 0 15px 0;
}
.results_address h3 {
    margin: 0px 0 0px 0;
}
.results_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: space-between;
            justify-content: space-between; 
    -webkit-align-items: flex-start; 
            align-items: flex-start;
    -webkit-align-content: flex-start;
            align-content: flex-start;

    font-size: 0.8em;
    line-height: 1.5em;
}
@media (min-width: 769px) {
    .results_container {
        font-size: 1em;
        line-height: 1.3em;
    }
}
.results_container .person {
    -webkit-flex-grow: 2;
            flex-grow: 2;
}

.results_container .specific {
    text-align: right;
    -webkit-flex-shrink : 0;
            flex-shrink : 0; 
    padding-left: 10px;
}
.result_disclaimer_top
{
  padding-bottom: 20px;
  font-size: 0.8em;
}

.result_disclaimer_top p
{
  margin-top: 0px;
}

.result_empty
{
  padding-bottom: 30px;
}

div.results_list:nth-child(odd) {
    padding: 5px 0px 5px 0px;
}
div.results_list:nth-child(even) {
    /* background: rgba(163,188,183,0.3);  */
    padding: 5px 0px 5px 0px;
    border-bottom: 1px #78b5ab solid; 
    border-top: 1px #78b5ab solid;
}

.recipient_error {
    margin-top: 40px;
}

.results_info {
    padding: 15px 15px 15px 15px;
    margin-left: -15px;
    margin-right: -15px;
}
@media (min-width: 769px) {
    .results_info {
        padding: 15px 30px 15px 30px;
        margin-left: -30px;
        margin-right: -30px;
    }
}

.details {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}


.tooltip_logo {
    width: 11px;
    opacity: 0.2;
}
@media (min-width: 769px) {
    .tooltip_logo {
        width: 13px;
    }
}
.tooltip {
    font-size: 0.8em;
    line-height: 1.5em;
    padding: 2px 5px 2px 5px;
    border-radius: 0px;
    opacity: 1 !important;
}

.quelle {
    
}
.quelle p {
    margin-bottom: 0px;
    font-size: 0.8em;
    line-height: 1.5em;
}


section.visits {
    background-color: #e8f4f2;
}
@media (min-width: 769px) {
    .results_list.visits {
        font-size: 0.8em;
        line-height: 1.5em;
    }
}
.visits p {
    margin-top: 0px;
    margin-bottom: 10px;
}

/* FOOTER */
footer {
    font-size: 0.8em;
    line-height: 1.5em;
}




.table {
    display: table;
    font-weight: bold;
    line-height: 1.2em;
    width: 100%;
}
.table-row {
    display: table-row; 
    vertical-align: middle;
}
.table-cell {
    display: table-cell; 
}
.year {
    padding-right: 20px;
    width: 50px;
}
.bar {
    width: 100%;
}
.sum {
    padding-left: 20px;
    text-align: right;
    white-space: nowrap;
}
.bar_sum {
    background: #ffffff;
    width: 1%;
    height: 10px;
    margin-bottom: 1px;
}

.pharmanote
{
    margin: 20px 0 0 0;
    padding: 10px;
    background-color: #9bded2;
    font-size: 0.8em;
}

.impressum_logo {
  display: float;
}

.impressum_logo img
{
  max-height: 60px;
  padding: 5px;
}

/* LOADER */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #78b5ab;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

